import styled from 'styled-components';

import Logo from 'assets/images/logo.svg';
import { fontSize, color, media } from 'components/_settings/variables';

export const NoteDescription = styled.span`
  font-size: ${fontSize.sm};
  font-weight: 500;
`;

export const NoteTitle = styled.span`
  display: block;
  font-size: 10px;
  font-weight: 700;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  color: #6b7696;
  margin-bottom: 5px;
`;

export const NoteLogo = styled(Logo)`
  width: 30px;
  height: 30px;
  fill: ${color.primary};
`;

export const NoteLogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  background-color: #e2fbff;
  border-radius: 50%;
  margin-right: 15px;
  flex-shrink: 0;

  ${media.sm} {
    margin-right: 10px;
  }
`;

export const Note = styled.div`
  position: absolute;
  left: 50%;
  bottom: 0;
  display: flex;
  align-items: center;
  max-width: 285px;
  width: 100%;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 14px 36px 150px -45px rgba(50, 50, 93, 0.5), 2px 2px 12px 0 rgba(26, 26, 67, 0.05);
  padding-top: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  transform: translateX(-50%) translateY(50%);

  ${media.sm} {
    max-width: 270px;
    padding-top: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    padding-left: 15px;
  }
`;

export const Image = styled.div`
  width: 100%;
  box-shadow: 0 20px 51px -15px rgba(16, 26, 34, 0.23);
  border-radius: 8px;
  margin-top: 67px;
`;

export const Wrapper = styled.div`
  max-width: 526px;
  position: relative;
`;
