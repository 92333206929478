import * as React from 'react';

import { Container } from 'components/shared/container';
import { Grid, GridCell } from 'components/shared/grid';
import { getTextWithoutParagraph } from 'utils';

import {
  Wrapper,
  Content,
  Label,
  StyledTitle,
  Description,
  Button,
  BackgroundFigure,
} from './case-study-intro.styles';
import { Illustration } from './illustration';

export const Intro = (props) => {
  const { label, title, description, buttonText, buttonFileUrl, illustration } = props;

  return (
    <Wrapper>
      <BackgroundFigure />

      <Container style={{ position: 'relative' }}>
        <Grid columns={2} gap={80} media={{ md: { columns: 1, gap: 40 } }}>
          <GridCell>
            <Content>
              <Label>{label}</Label>
              <StyledTitle
                as="h1"
                size="xl"
                dangerouslySetInnerHTML={{
                  __html: getTextWithoutParagraph(title),
                }}
              />

              <Description>{description}</Description>
              <Button component="a" href={buttonFileUrl} target="__blank" arrow={false}>
                {buttonText}
              </Button>
            </Content>
          </GridCell>
          <GridCell>
            {illustration && (
              <Illustration
                title={illustration.title}
                description={illustration.description}
                svg={illustration.svg}
              />
            )}
          </GridCell>
        </Grid>
      </Container>
    </Wrapper>
  );
};
