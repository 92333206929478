import styled from 'styled-components';

import { media } from 'components/_settings/variables';

import IconSvg from './icon.svg';

export const Wrapper = styled.div`
  max-width: 665px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 14px 36px 150px -45px rgba(50, 50, 93, 0.5), 2px 2px 12px 0 rgba(26, 26, 67, 0.05);
  margin-bottom: 90px;

  ${media.sm} {
    max-width: 480px;
    margin-bottom: 60px;
  }

  form {
    padding: 50px;

    ${media.sm} {
      padding: 25px;
    }
  }
`;

export const FormHeader = styled.div`
  border-bottom: 1px solid #eaeef2;
  padding: 32px 50px;
  display: flex;

  ${media.sm} {
    display: block;
    text-align: center;
  }
`;

export const Icon = styled(IconSvg)`
  width: 70px;
  height: 70px;
  margin-right: 32px;
  flex-shrink: 0;

  ${media.sm} {
    margin: 0 auto 16px;
  }
`;

export const HeaderContent = styled.div`
  color: #56556c;
`;

export const Title = styled.div`
  font-size: 24px;
  font-weight: 500;
  line-height: 1.17;
  margin-bottom: 4px;
`;

export const Description = styled.div`
  line-height: 1.75;
  font-size: 16px;
`;
