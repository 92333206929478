import * as React from 'react';

import {
  Container,
  Items,
  Item,
  ItemTitle,
  ItemValue,
  PageContent,
} from './case-study-content.styles';

export const Content = (props) => {
  const { content, items } = props;

  return (
    <Container>
      <PageContent dangerouslySetInnerHTML={{ __html: content }} />

      <Items>
        {items.map((item) => (
          <Item>
            <ItemTitle>{item.title}</ItemTitle>
            <ItemValue>{item.value}</ItemValue>
          </Item>
        ))}
      </Items>
    </Container>
  );
};
