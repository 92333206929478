import styled from 'styled-components';

import { media } from '../../../_settings/variables';
import { Container as SharedContainer } from '../../../shared/container';

export const Container = styled(SharedContainer)`
  position: relative;
  margin-bottom: 80px;
  display: flex;

  ${media.md} {
    flex-direction: column;
  }
`;

export const Items = styled.div`
  ${media.md} {
    order: -1;
    margin-bottom: 50px;
  }
`;

export const Item = styled.div`
  & + & {
    margin-top: 35px;
  }
`;

export const ItemTitle = styled.div`
  font-weight: 500;
  margin-bottom: 14px;
`;
export const ItemValue = styled.div`
  font-size: 36px;
  line-height: 1.33;
  color: #5d7ad8;
`;

export const PageContent = styled.div`
  width: 690px;
  flex-shrink: 0;
  font-size: 24px;
  line-height: 1.58;
  color: #56556c;
  margin-right: 100px;

  ${media.lg} {
    max-width: 550px;
    margin-right: 50px;
  }

  ${media.md} {
    max-width: 100%;
    margin: 0;
  }

  h2 {
    font-size: 42px;
    margin-bottom: 24px;
    color: #2c295d;
  }

  h2:first-child {
    margin-top: 0;
  }
`;
