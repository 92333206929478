import { graphql } from 'gatsby';
import * as React from 'react';

import Layout from 'components/_settings/layout';
import { Footer } from 'components/blocks/footer';
import { Header } from 'components/blocks/header';
import { Content } from 'components/pages/case-study/content';
import { Form } from 'components/pages/case-study/form';
import { Intro } from 'components/pages/case-study/intro';
import { buildPageMetadata } from 'utils/data-builders';
import { extractItems } from 'utils/extract-items';

const CaseStudyModified = (props) => {
  const {
    data: { wpPage, form },
  } = props;
  const { content, acf } = wpPage;
  const contentItems = extractItems(acf, 'section_2_item');

  return (
    <Layout pageMetadata={buildPageMetadata(wpPage)}>
      <Header withoutActions={!acf.showHeader} theme="light" />
      <Intro
        label={acf.section1TextAboveTitle}
        title={acf.section1Title}
        description={acf.section1Description}
        buttonText={acf.section1ButtonText}
      />
      <Content content={content} items={contentItems} />
      <Form title={acf.formTitle} description={acf.formDescription} formData={form} />
      {acf.showFooter && <Footer />}
    </Layout>
  );
};

export default CaseStudyModified;

export const query = graphql`
  query ($id: String!, $formId: Int!) {
    wpPage(id: { eq: $id }) {
      ...wordpressPagePageMetadata
      content
      acf {
        section1TextAboveTitle
        section1Title
        section1Description
        section1ButtonText
        section1Illustration
        section1IllustrationTitle
        section1IllustrationDescription
        formTitle
        formDescription
        showHeader
        showFooter
      }
    }
    form: gfForm(formId: { eq: $formId }) {
      formId
      apiURL
      formFields {
        id
        label
        type
        defaultValue
        isRequired
        choices
        conditionalLogic
        description
        cssClass
      }
      confirmations {
        message
      }
      button {
        text
      }
    }
  }
`;
