import * as React from 'react';

import {
  Wrapper,
  Image,
  Note,
  NoteLogoWrapper,
  NoteLogo,
  NoteTitle,
  NoteDescription,
} from './case-study-intro-illustration.styles';

export const Illustration = (props) => {
  const { title, description, svg } = props;

  return (
    <Wrapper>
      <Image dangerouslySetInnerHTML={{ __html: svg }} />

      <Note>
        <NoteLogoWrapper>
          <NoteLogo />
        </NoteLogoWrapper>

        <div>
          <NoteTitle>{title}</NoteTitle>
          <NoteDescription>{description}</NoteDescription>
        </div>
      </Note>
    </Wrapper>
  );
};
