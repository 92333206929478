import styled from 'styled-components';

import { fontSize, media } from 'components/_settings/variables';
import { Button as SharedButton } from 'components/shared/button';
import { Title } from 'components/shared/title';

import BackgrounfFigureSvg from './background-figure.svg';

export const Button = styled(SharedButton)``;
//   display: inline-block;
//   vertical-align: top;
//   font-size: 18px;
//   font-weight: 500;
//   text-decoration: none;
//   color: ${color.text.tertiary};
//   background-color: ${rgba(color.primary, 0.75)};
//   border-radius: 4px;
//   box-shadow: 0 10px 20px 0 #9de2ec;
//   padding-top: 20px;
//   padding-right: 40px;
//   padding-bottom: 20px;
//   padding-left: 40px;
//   transition: ${transition.base};
//
//   ${media.sm} {
//     font-size: ${fontSize.sm};
//     padding-right: 30px;
//     padding-left: 30px;
//   }
//
//   &:hover {
//     background-color: ${color.primary};
//   }
// `;

export const Description = styled.p`
  font-size: ${fontSize.lg};
  line-height: 1.58;
  color: #e2c8ff;
  margin-bottom: 40px;
  opacity: 0.73;

  ${media.sm} {
    font-size: ${fontSize.md};
    margin-bottom: 20px;
  }
`;

export const StyledTitle = styled(Title)`
  margin-bottom: 30px;
  color: #e2c8ff;

  ${media.sm} {
    margin-bottom: 20px;
  }
`;

export const Label = styled.div`
  font-size: ${fontSize.lg};
  line-height: 1.75;
  color: rgba(226, 200, 255, 0.5);
  margin-bottom: 27px;
  font-weight: 500;
`;

export const Content = styled.div`
  max-width: 560px;

  ${media.sm} {
    max-width: 400px;
  }
`;

export const Wrapper = styled.section`
  position: relative;
  padding: 146px 0 227px;
  overflow: hidden;
  background-color: #07112e;
`;

export const BackgroundFigure = styled(BackgrounfFigureSvg)`
  display: block;
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
`;
