export function extractItems(object, prefix) {
  const result = [];
  const keys = Object.keys(object);
  for (let i = 0; i < keys.length; i += 1) {
    const key = keys[i];

    if (key.startsWith(prefix)) {
      const suffix = key.substr(prefix.length);
      const parts = suffix.split(/[^0-9.]/g, 1);
      const index = parseInt(parts[0], 10) - 1;

      // eslint-disable-next-line
      if (!isNaN(index)) {
        const fieldName = suffix.substr(parts[0].length).toLowerCase() || 'text';

        result[index] = result[index] || {};
        result[index][fieldName] = object[key];
      }
    }
  }

  return result;
}
